var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('HeaderComp', {
    attrs: {
      "logo": "hide",
      "back": _vm.headerBack,
      "back-title": "Notes"
    }
  }), _c('c-box', {
    attrs: {
      "max-w": ['inherit', '1200px'],
      "w": "100%",
      "mx": "auto",
      "mt": ['24px', '60px']
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "as": "router-link",
      "to": "/"
    }
  }, [_vm._v(" Beranda ")])], 1), _c('c-breadcrumb-item', {
    attrs: {
      "is-current-page": ""
    }
  }, [_c('c-breadcrumb-link', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v(" Notes ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "max-w": "1200px",
      "border-top": [null, '1px solid #F2F2F2'],
      "mt": [null, '30px']
    }
  }, [_c('c-flex', {
    attrs: {
      "ml": [null, '52px'],
      "w": ['100%', '800px'],
      "justify-content": "center"
    }
  }, [_c('router-view')], 1)], 1)], 1), _c('FooterComp'), _c('NavBottom')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }