<template>
  <c-flex flex-dir="column">
    <HeaderComp
      logo="hide"
      :back="headerBack"
      back-title="Notes"
    />

    <c-box
      :max-w="['inherit', '1200px']"
      w="100%"
      mx="auto"
      :mt="['24px', '60px']"
    >
      <c-breadcrumb
        separator="›"
        :d="['none', 'block']"
      >
        <c-breadcrumb-item>
          <c-breadcrumb-link
            as="router-link"
            to="/"
          >
            Beranda
          </c-breadcrumb-link>
        </c-breadcrumb-item>
        <c-breadcrumb-item is-current-page>
          <c-breadcrumb-link href="#">
            Notes
          </c-breadcrumb-link>
        </c-breadcrumb-item>
      </c-breadcrumb>

      <c-flex
        justify-content="center"
        max-w="1200px"
        :border-top="[null, '1px solid #F2F2F2']"
        :mt="[null, '30px']"
      >
        <c-flex
          :ml="[null, '52px']"
          :w="['100%', '800px']"
          justify-content="center"
        >
          <router-view />
        </c-flex>
      </c-flex>
    </c-box>

    <FooterComp />

    <NavBottom />
  </c-flex>
</template>

<script>
import { mapState } from 'vuex'
import HeaderComp from '@/components/header/index.vue'
import FooterComp from '@/components/layouts/footer/index.vue'
import NavBottom from '@/components/nav-bottom.vue'
export default {
  name: 'FollowUpNoteLayout',
  components: {
    HeaderComp,
    FooterComp,
    NavBottom,
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    isClient() {
      if (this.user && this.user.role == 'client') return true
      return false
    },
    headerBack() {
      if (this.isClient) {
        return 'client.index'
      }
      return ''
    },
  },
}
</script>

<style scoped>
body {
  overflow: inherit;
}
[data-chakra-component="CBreadcrumb"] {
  color: var(--colors-primary-400);
}

[data-chakra-component="CBreadcrumb"]
  /deep/
  [data-chakra-component="CBreadcrumbItem"] {
  color: var(--colors-primary-400);
}
[data-chakra-component="CBreadcrumb"]
  /deep/
  [data-chakra-component="CBreadcrumbItem"]:last-child {
  color: #333;
}
</style>